function getConfig() {
  if (window !== null && window._env_ !== undefined) {
    return {
      REACT_APP_CONNECTION_URI: window._env_.REACT_APP_CONNECTION_URI,
      REACT_APP_SUBSCRIPTION_URI: window._env_.REACT_APP_SUBSCRIPTION_URI,
      REACT_APP_KRAY_SEARCH_MOD_URI: window._env_.REACT_APP_KRAY_SEARCH_MOD_URI,
      REACT_APP_CHATAPI_URL: window._env_.REACT_APP_CHATAPI_URL,
      REACT_APP_AUTHHANDLER_URL: window._env_.REACT_APP_AUTHHANDLER_URL,
      REACT_APP_BACKEND_URL: window._env_.REACT_APP_BACKEND_URL,
      REACT_APP_ORGANIZATION_REALM: process.env.REACT_APP_ORGANIZATION_REALM,
      REACT_APP_CLIENTID: process.env.REACT_APP_CLIENTID,
      TOKEN_END_POINT: `${window._env_.REACT_APP_AUTHHANDLER_URL}/auth/refresh/${process.env.REACT_APP_REALM}/${process.env.REACT_APP_CLIENTID}`,
      REACT_APP_WEBSOCKET_REFRESH_TIME: parseInt(process.env.REACT_APP_WEBSOCKET_REFRESH_TIME),
      REACT_APP_VDI_ENDPOINT: process.env.REACT_APP_VDI_ENDPOINT,
      REACT_APP_USER_EMAIL: window._env_.REACT_APP_USER_EMAIL,
      REACT_APP_DOCS_API_URL: window._env_.REACT_APP_DOCS_API_URL,
      REACT_APP_DOCS_API_KEY: window._env_.REACT_APP_DOCS_API_KEY,
      REACT_APP_LOCATION_BASE_URL: window._env_.REACT_APP_LOCATION_BASE_URL,
      REACT_APP_LOCATION_TOKEN: window._env_.REACT_APP_LOCATION_TOKEN,
      REACT_APP_LOCATION_EMAIL: window._env_.REACT_APP_LOCATION_EMAIL,
      REACT_APP_ANALYTICS_URL: window._env_.REACT_APP_ANALYTICS_URL,
      REACT_APP_CHATBOT_URL: window._env_.REACT_APP_CHATBOT_URL

    };
  } else {
    return {
      REACT_APP_CONNECTION_URI: process.env.REACT_APP_CONNECTION_URI,
      REACT_APP_SUBSCRIPTION_URI: process.env.REACT_APP_SUBSCRIPTION_URI,
      REACT_APP_KRAY_SEARCH_MOD_URI: process.env.REACT_APP_KRAY_SEARCH_MOD_URI,
      REACT_APP_CHATAPI_URL: process.env.REACT_APP_CHATAPI_URL,
      REACT_APP_AUTHHANDLER_URL: process.env.REACT_APP_AUTHHANDLER_URL,
      REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
      REACT_APP_ORGANIZATION_REALM: process.env.REACT_APP_ORGANIZATION_REALM,
      REACT_APP_CLIENTID: process.env.REACT_APP_CLIENTID,
      TOKEN_END_POINT: `${process.env.REACT_APP_AUTHHANDLER_URL}/auth/refresh/${process.env.REACT_APP_REALM}/${process.env.REACT_APP_CLIENTID}`,
      REACT_APP_WEBSOCKET_REFRESH_TIME: parseInt(process.env.REACT_APP_WEBSOCKET_REFRESH_TIME),
      REACT_APP_VDI_ENDPOINT: process.env.REACT_APP_VDI_ENDPOINT,
      REACT_APP_USER_EMAIL: process.env.REACT_APP_USER_EMAIL,
      REACT_APP_DOCS_API_URL: process.env.REACT_APP_DOCS_API_URL,
      REACT_APP_DOCS_API_KEY: process.env.REACT_APP_DOCS_API_KEY,
      REACT_APP_LOCATION_BASE_URL: process.env.REACT_APP_LOCATION_BASE_URL,
      REACT_APP_LOCATION_TOKEN: process.env.REACT_APP_LOCATION_TOKEN,
      REACT_APP_LOCATION_EMAIL: process.env.REACT_APP_LOCATION_EMAIL,
      REACT_APP_ANALYTICS_URL: process.env.REACT_APP_ANALYTICS_URL,
      REACT_APP_CHATBOT_URL: process.env.REACT_APP_CHATBOT_URL
    };
  }
}
export default getConfig;