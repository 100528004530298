import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedLead: null,
    selectedQuery: null,
    selectedResponse: null,
    activeChatbox: null
};

export const trainingInterfaceSlice = createSlice({
    name: "trainingInterface",
    initialState,
    reducers: {
        setChatboxDetails(state, action) {
            const { index, userMessage, botMessage, leadId } = action.payload;
            state.activeChatbox = index;
            state.selectedQuery = userMessage;
            state.selectedLead = leadId;
            state.selectedResponse = botMessage;
          },
        resetState(state) {
            return initialState;
          },
    },

})

export const { resetState, setChatboxDetails } = trainingInterfaceSlice.actions;
export default trainingInterfaceSlice.reducer;